<template>
  <div class="issue-types">
    <v-toolbar flat>
      <v-toolbar-title>Tipo de Incidencias</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="ma-2" rounded color="primary" to="/settings/issue-types/new">Nuevo</v-btn>
    </v-toolbar>

    <v-row class="mr-5">
      <v-spacer></v-spacer>
      <v-col justify="end" md="4">
      <v-text-field
        v-model="search"
        class="ml-5"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        clearable
        hide-details>
      </v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      flat
      :search="search"
      :headers="headers"
      :items="issueTypes"
      :loading="getIssueTypesIsLoading"
      height="536px"
      class="px-4"
    >
      <template v-slot:item.name="{ item }">
        <v-avatar size="32" class="mr-2">
          <v-img :src="item.iconUrl"></v-img>
        </v-avatar>
        {{item.name}}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-row justify="end">
          <v-btn
            small
            rounded
            outlined
            color="primary"
            :to="{name: 'issue-types-edit', params: { issueTypeId: item.id }}"
          >Editar</v-btn>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { to } from '@/helpers'
import { ISSUE_TYPES_GET_ISSUE_TYPES } from '@/store/actions.type'
import { mapGetters } from 'vuex'

export default {
  name: 'IssueTypes',
  props: {},
  mounted() {
    this.getIssueTypes()
  },
  data() {
    return {
      isLoading: true,
      error: null,
      search: '',
      issueTypes: [],
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Descripción', value: 'description' },
        { text: '', value: 'actions' }
      ]
    }
  },
  computed: {
    ...mapGetters(['getIssueTypesIssueTypes', 'getIssueTypesIsLoading'])
  },
  methods: {
    async getIssueTypes() {
      const [err] = await to(this.$store.dispatch(ISSUE_TYPES_GET_ISSUE_TYPES))
      if (err) {
        this.error = err
        this.isLoading = false
      } else {
        this.issueTypes = this.getIssueTypesIssueTypes
        this.isLoading = false
      }
    }
  },
  components: {}
}
</script>
